new WOW().init();

// Responses without this status will trigger error conditions
$.fn.api.settings.successTest = function(response) {
    return response.status == 'success';
};

/**
 * Set csrf token on all http requests.
 */
$.fn.api.settings.beforeXHR = function(xhr) {
    xhr.setRequestHeader('X-CSRF-TOKEN', Glinse.csrfToken);
};

window.showPageLoader = function () {
    $('#page_dimmer').addClass('active');
};

window.hidePageLoader = function () {
    $('#page_dimmer').removeClass('active');
};

Lang.setLocale(appLocale);

if( appLocale == "ar" ) {
    moment.locale('ar-tn');
}
if( appLocale == "fr" ) {
    moment.locale('fr');
}

$(document).on('keyup input change', 'textarea[data-autoresize]', function () {
    var offset = this.offsetHeight - this.clientHeight;
    
    $(this).css('height', 'auto').css('height', this.scrollHeight + offset);
});

/**
 * Datatables
 */
var datatablesSettingsObject = function () {
    return {
        stateSave: false,
        "order": [[0, "desc"]],
        "language": {
            "url": baseUrl + "/build/vendor/datatables/i18n/"+appLocale+".json"
        },
        initComplete: function () {
            var filterableColumns = [];
            
            // Look for filterable columns
            this.api().columns().every(function(){
                var headerNode = $(this.header());
                
                var selectFilter = headerNode.attr('data-dt-select-filter');
                var textFilter   = headerNode.attr('data-dt-text-filter');
                var defaultValue = headerNode.attr('data-dt-default-filter');
                
                // Set the default value to empty if the attribute is not provided
                if( ! (typeof defaultValue !== typeof undefined && defaultValue !== false) )
                {
                    defaultValue = '';
                }
                
                if( typeof selectFilter !== typeof undefined && selectFilter !== false )
                {
                    filterableColumns.push({
                        type: 'select',
                        column: this,
                        headerNode: headerNode,
                        defaultValue: defaultValue
                    });
                }
                
                if( typeof textFilter !== typeof undefined && textFilter !== false )
                {
                    filterableColumns.push({
                        type: 'text',
                        column: this,
                        headerNode: headerNode,
                        defaultValue: defaultValue
                    });
                }
            });
            
            // No filterable columns, then just return
            if ( filterableColumns.length == 0 )
            {
                return;
            }
            
            var filterZoneHtml = '<div class="dataTableFilterZone ui grid six columns"></div>';
            
            if( ! $(this).hasClass('dt-basic-filters-ui') )
            {
                filterZoneHtml = '<div class="ui segment"><h5 class="ui dividing header">'+ Lang.get('app.dataTable.filterSegmentHeader') +'</h5>'+filterZoneHtml+'</div>';
            }
            
            var filterZone = $(this).closest('.dataTables_wrapper').prepend($(filterZoneHtml)).find('.dataTableFilterZone');
            
            var triggerDraw = false;
            
            _.forEach(filterableColumns, function (filter) {
                if( filter.type == 'select' )
                {
                    var column = filter.column;
                    
                    var select = $('<div class="column"><select class="ui search fluid dropdown"><option value="">'+Lang.get('app.dataTable.filterBy')+' '+ filter.headerNode.text() +'</option></select></div>')
                        .appendTo(filterZone).find('select')
                        .on('change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );

                            column
                                .search(val ? '^' + val + '$' : '', true, false)
                                .draw();
                        });

                    column.data().unique().sort().each(function (d, j) {
                        if(d.length == 0) return;
                        
                        d = d.replace(/(<([^>]+)>)/ig,"").trim();
                        
                        select.append('<option value="' + d + '">' + d + '</option>')
                    });
                    
                    select.val(filter.defaultValue);
                    
                    if( filter.defaultValue )
                    {
                        column.search('^' + filter.defaultValue + '$', true, false);
                        triggerDraw = true;
                    }
                }
                
                if( filter.type == 'text' )
                {
                    var column = filter.column;
                    
                    var input = '<div class="column"><div class="ui fluid icon input">' +
                                    '<input type="text" placeholder="'+Lang.get('app.dataTable.filterBy')+' '+ filter.headerNode.text() +'" />'+
                                    '<i class="search icon"></i>'+
                                '</div></div>';
                    
                    $(input)
                        .appendTo(filterZone).find('input')
                        .val(filter.defaultValue)
                        .on('keyup change', function () {
                            if (column.search() !== this.value) {
                                column
                                    .search(this.value)
                                    .draw();
                            }
                        });
                    
                    if( filter.defaultValue )
                    {
                        column.search(filter.defaultValue);
                        triggerDraw = true;
                    }
                }
            });
            
            if( triggerDraw )
            {
                this.api().draw();
            }
            
            filterZone.find('select.dropdown').dropdown({
                fullTextSearch: true,
                placeholder: false,
                forceSelection: false
            });
            
            if( $(this).hasClass('dt-basic-filters-ui') )
            {
                filterZone.css('margin-bottom', '0.5em');
            }
        }
    };
};

/**
 * Clipboard
 */
var clipboard = new Clipboard('.CopyButton');

$(document).ready(function () {
    $('.CopyButton').popup({
        on: 'manual'
    });

    /**
     * Table row checks any child checkbox
     */
    $(document).on('click', 'table.CheckableRows tr', function(event)
    {
        if( $(event.target).is('td') )
        {
            $(this).find('input[type=checkbox],input[type=radio]').trigger('click');
        }
    });

    //Page loader
    $('body').on('click', '*[data-page-dimmer]', function () {
        $('#page_dimmer').toggleClass('active');
    });
});

clipboard.on('success', function(e) {
    $('.CopyButton').popup('show');

    setTimeout(function () {
        $('.CopyButton').popup('hide');
    }, 1000);

    e.clearSelection();
});

/**
 * Notifications
 */

function notifyWeb(settings, type, pluginSettings) {
    var theme, icon, options;

    switch (type) {
        case 'info':
            theme = 'awesome blue';
            icon  = 'fa fa-info';
            break;

        case 'success':
            theme = 'awesome green';
            icon  = 'fa fa-check';
            break;

        case 'error':
            theme = 'awesome error';
            icon  = 'fa fa-times';
            break;

        case 'warning':
            theme = 'awesome yellow';
            icon  = 'fa fa-exclamation-triangle';
            break;

        default:
            theme = 'awesome blue';
            icon  = 'fa fa-info';
            break;
    }

    options = {
        'theme': theme,
        'content': {
            title  : settings.title,
            message: settings.message,
            info   : _.has(settings, "extra") ? settings.extra : "",
            icon   : icon
        },
        'position': _.has(settings, "position") ? settings.position : 'top right',
        'cssanimationIn': _.has(settings, "animationIn") ? settings.animationIn : 'slideInDown',
        'cssanimationOut': _.has(settings, "animationOut") ? settings.animationOut : 'slideOutRight',
        onClick: _.has(settings, "onClick") ? settings.onClick : function () {}
    };

    if( pluginSettings )
    {
        options = _.extend(options, pluginSettings);
    }

    return $.amaran(options);
}

function notifyDesktop(settings, type, pluginSettings) {
    Push.create(settings.title, {
        body: settings.message,
        icon: baseUrl + "/build/images/notifications/desktop_notification_logo.png",
        timeout: 10000,
        onClick: function () {
            window.focus();

            if( _.has(settings, "onClick") ) {
                settings.onClick();
            }

            this.close();
        },
        tag: pluginSettings.tag
    });
}

function notify(settings, type, pluginSettings) {
    /*var desktopNotificationPermission = Push.Permission.get();

    if( Push.isSupported && desktopNotificationPermission != Push.Permission.DENIED )
    {

    }*/

    notifyWeb(settings, type, pluginSettings);

    if( settings.desktopNotifications )
    {
        notifyDesktop(settings, type, pluginSettings);
    }
}

function url(path) {
    return window.App.baseUrl + path;
}

function flattenLaravelErrors(errors) {
    var flatErrors = [];
    
    for(var k in errors) {
        for(var index in errors[k]) {
            flatErrors.push(errors[k][index]);
        }
    }
    
    return flatErrors;
}

function alertLaravelErrors(errors) {
    swal(
      Lang.get('common.error'),
      errors.join('\n'),
      'error'
    );
}

function handleLaravelAxiosErrorResponse(error) {
    //Validation error
    if( error.response.status == 422 ) {
        alertLaravelErrors(flattenLaravelErrors(error.response.data));
    } 
    // Some other error
    else {
        swal.close();
        notifyWeb({
            title: Lang.get('common.error'),
            message: Lang.get('common.could_not_carry_action_please_try_later')
        }, 'error', {
            delay: 5000
        });
    }
}