/**
 * Sidebar
 */
$('#MobileMenu')
    .sidebar({
        transition: 'overlay'
    })
    .sidebar('attach events', '#MobileMenuTrigger')
;

/**
 * Login popup
 */
$('#loginMenu .browse').popup({
    on: "click",
    inline: true,
    hoverable: false,
    position: 'bottom left'
});

$(document).ready(function () {
    $('#header_logo').on('mouseenter', function () {
        $(this).removeClass('wow flipInY').attr('style', '').addClass('animated swing')
                .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                    $(this).removeClass('animated swing');
                });
    });
});